import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  HomeIcon,
  GlobeAsiaAustraliaIcon,
  MapPinIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  TagIcon,
  MoonIcon,
  SunIcon,
  UsersIcon,
  DocumentTextIcon,
  ChatBubbleLeftRightIcon,
  RectangleStackIcon,
  ArrowUturnLeftIcon,
  QuestionMarkCircleIcon,
  QrCodeIcon,
} from "@heroicons/react/16/solid";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "./components/dropdown";
import { SidebarLayout } from "./components/sidebar-layout";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "./components/sidebar";
import { Avatar } from "./components/avatar";
import { useUser } from "./context/UserContext";
import { useProperty } from "./context/PropertyContext";
import { Heading } from "./components/heading";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Switch, SwitchField } from "./components/switch";
import { Label } from "./components/fieldset";
import BuddyDialogInput from "./components/buddy-dialog";

interface PageProps {
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
  const { user, logout } = useUser();
  const { property } = useProperty();
  const location = useLocation();

  const [isBuddyOpen, setIsBuddyOpen] = useState(false);

  const [colorScheme, setColorScheme] = useState<"dark" | "light">("light");

  const handleToggleColorScheme = () => {
    console.log("toggle theme");
    if (colorScheme === "dark") {
      localStorage.setItem("theme", "light");
      setColorScheme("light");
    } else {
      localStorage.setItem("theme", "dark");
      setColorScheme("dark");
    }
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setColorScheme("dark");
    } else {
      setColorScheme("light");
    }
  }, []);

  return (
    <div className={colorScheme}>
      <SidebarLayout
        navbar={<Heading>JUHUU Dashboard</Heading>}
        sidebar={
          <Sidebar>
            <SidebarHeader>
              {property !== null && (
                <>
                  <Dropdown>
                    <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                      <Avatar
                        src={property?.iconLight}
                        square={false}
                        initials={
                          property.iconLight === null
                            ? property.name.slice(0, 2)
                            : undefined
                        }
                      />
                      <SidebarLabel>
                        {property?.name || "No company selected"}
                      </SidebarLabel>
                      <ChevronDownIcon />
                    </DropdownButton>
                    <DropdownMenu
                      className="min-w-80 lg:min-w-64"
                      anchor="bottom start"
                    >
                      <DropdownItem
                        href={"/properties/" + property?.id + "/edit"}
                      >
                        <Cog8ToothIcon />
                        <DropdownLabel>Settings</DropdownLabel>
                      </DropdownItem>
                      <DropdownItem href={"/properties"}>
                        <ArrowUturnLeftIcon />
                        <DropdownLabel>List of companies</DropdownLabel>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <SidebarSection>
                    {/* <SidebarItem href="/search">
                      <MagnifyingGlassIcon />
                      <SidebarLabel>Search</SidebarLabel>
                    </SidebarItem> */}
                    <SidebarItem onClick={() => setIsBuddyOpen(true)}>
                      <ChatBubbleLeftRightIcon />
                      <SidebarLabel>JUHUU Buddy</SidebarLabel>
                    </SidebarItem>
                  </SidebarSection>
                </>
              )}
            </SidebarHeader>
            <SidebarBody>
              {property !== null && (
                <>
                  <SidebarSection>
                    <SidebarItem
                      href={"/properties/" + property?.id}
                      current={location.pathname.split("/")[3] === undefined}
                    >
                      <HomeIcon />
                      <SidebarLabel>Home</SidebarLabel>
                    </SidebarItem>
                    <SidebarHeading className="mt-8">
                      Support & Documentation
                    </SidebarHeading>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/users"}
                      current={
                        window.location.pathname.split("/")[3] === "users"
                      }
                    >
                      <UsersIcon />
                      <SidebarLabel>Customers</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/articles"}
                      current={
                        window.location.pathname.split("/")[3] === "articles"
                      }
                    >
                      <DocumentTextIcon />
                      <SidebarLabel>Articles</SidebarLabel>
                    </SidebarItem>

                    <SidebarHeading className="mt-8">
                      Appearance & Branding
                    </SidebarHeading>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/points"}
                      current={
                        window.location.pathname.split("/")[3] === "points"
                      }
                    >
                      <MapPinIcon />
                      <SidebarLabel>Markers</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/locations"}
                      current={
                        window.location.pathname.split("/")[3] === "locations"
                      }
                    >
                      <GlobeAsiaAustraliaIcon />
                      <SidebarLabel>Locations</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/links"}
                      current={
                        window.location.pathname.split("/")[3] === "links"
                      }
                    >
                      <QrCodeIcon />
                      <SidebarLabel>QR-Codes</SidebarLabel>
                    </SidebarItem>
                    <SidebarHeading className="mt-8">
                      Business & Operations
                    </SidebarHeading>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/sessions"}
                      current={location.pathname.split("/")[3] === "sessions"}
                    >
                      <RectangleStackIcon />
                      <SidebarLabel>Rentals & Reservations</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/tariffs"}
                      current={location.pathname.split("/")[3] === "tariffs"}
                    >
                      <TagIcon />
                      <SidebarLabel>Tariffs</SidebarLabel>
                    </SidebarItem>
                    <SidebarHeading className="mt-8">
                      Marketplace
                    </SidebarHeading>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/products"}
                      current={
                        window.location.pathname.split("/")[3] === "products"
                      }
                    >
                      <ShoppingBagIcon />
                      <SidebarLabel>Products</SidebarLabel>
                    </SidebarItem>
                    <SidebarHeading className="mt-8">Legal</SidebarHeading>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/terms"}
                      current={
                        window.location.pathname.split("/")[3] === "terms"
                      }
                    >
                      <ShieldCheckIcon />
                      <SidebarLabel>Terms</SidebarLabel>
                    </SidebarItem>

                    <SidebarHeading className="mt-8">
                      Finance & Billing
                    </SidebarHeading>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/payments"}
                      current={
                        window.location.pathname.split("/")[3] === "payments"
                      }
                    >
                      <SidebarLabel>Payments</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/payouts"}
                      current={
                        window.location.pathname.split("/")[3] === "payouts"
                      }
                    >
                      <SidebarLabel>Payouts</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/accountingAreas"}
                      current={
                        window.location.pathname.split("/")[3] ===
                        "accountingAreas"
                      }
                    >
                      <SidebarLabel>Accounting Areas</SidebarLabel>
                    </SidebarItem>
                    <SidebarHeading className="mt-8">
                      Engineering
                    </SidebarHeading>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/devices"}
                      current={location.pathname.split("/")[3] === "devices"}
                    >
                      <SidebarLabel>Devices</SidebarLabel>
                    </SidebarItem>

                    <SidebarItem
                      href={"/properties/" + property?.id + "/deviceTemplates"}
                      current={
                        window.location.pathname.split("/")[3] ===
                        "deviceTemplates"
                      }
                    >
                      <SidebarLabel>Device Templates</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/connectors"}
                      current={location.pathname.split("/")[3] === "connectors"}
                    >
                      <SidebarLabel>Connectors</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem
                      href={"/properties/" + property?.id + "/sims"}
                      current={location.pathname.split("/")[3] === "sims"}
                    >
                      <SidebarLabel>SIM Cards</SidebarLabel>
                    </SidebarItem>
                  </SidebarSection>
                </>
              )}
              <SidebarSpacer />
              <SidebarSection>
                <SidebarHeading className="mt-8">Other</SidebarHeading>
                <SidebarItem
                  href="https://identity.juhuu.app/support"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <QuestionMarkCircleIcon />
                  <SidebarLabel>Help & Support</SidebarLabel>
                </SidebarItem>
                <SidebarItem>
                  {colorScheme === "dark" ? <MoonIcon /> : <SunIcon />}
                  <SwitchField>
                    <Label>Darkmode</Label>
                    <Switch
                      onChange={handleToggleColorScheme}
                      checked={colorScheme === "dark"}
                    />
                  </SwitchField>
                </SidebarItem>
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter className="max-lg:hidden">
              <Dropdown>
                <DropdownButton as={SidebarItem}>
                  <span className="flex min-w-0 items-center gap-3">
                    <span className="min-w-0">
                      <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                        {user?.name || "Not logged in"}
                      </span>
                      <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                        {user?.billingEmail}
                      </span>
                    </span>
                  </span>
                  <ChevronUpIcon />
                </DropdownButton>
                <DropdownMenu className="min-w-64" anchor="top start">
                  {/* <DropdownItem href="/my-profile">
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem> */}
                  <DropdownItem href="https://identity.juhuu.app/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                  {/* <DropdownDivider />
                <DropdownItem href="/privacy-policy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem> */}
                  {/* <DropdownDivider /> */}
                  <DropdownItem onClick={logout}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel onClick={logout}>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </SidebarFooter>
          </Sidebar>
        }
      >
        <>
          {children}
          <BuddyDialogInput
            isOpen={isBuddyOpen}
            onClose={() => setIsBuddyOpen(false)}
          />
        </>
      </SidebarLayout>
    </div>
  );
};

export default Page;
